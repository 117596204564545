import Splide from '@splidejs/splide';
import {i18n} from './i18n';

export function homeSlider(element) {
    const splide = new Splide(element, {
        type: 'fade',
        rewind: true,
        interval: element.dataset.timer * 1000,
        pagination: false,
        autoplay: true,
        mediaQuery: 'max',
        breakpoints: {
            768: {
                height: 'auto',
            },
        },
        i18n,
    });

    splide.on('mounted', () => {
        let firstSlide = splide.Components.Elements.slides[0];
        firstSlide.querySelector('.block-slider-home-figure').classList.add('active');
    });

    splide.mount();

    splide.on('active', e => {
        const element = e.slide.querySelector('.block-slider-home-figure');
        element.classList.add('active');
    });

    splide.on('inactive', e => {
        const element = e.slide.querySelector('.block-slider-home-figure');
        element.classList.remove('active');
    });
}
