<template>
    <div class="row jc-sb">
        <aside class="col-3 col-md-4 col-sm-12">
            <div id="modal-filter" class="js-modal js-modal-mobile">
                <section class="js-modal-section">
                    <header class="js-modal-header mb-l pin-xl pbl-xxl bg-dark d-n d-b@<sm">
                        <h3 class="tt-5 tt-u fw-700">{{ __('Filtres', 'clever-age') }}</h3>
                        <button type="button" class="js-modal-close">
                            <svg tabindex="0" focusable="false" aria-hidden="true" width="10" height="10">
                                <use :xlink:href="getThemeFileUri('build/svg/sprite.svg#cross')"></use>
                            </svg>
                            <span class="visually-hidden">{{ __('Fermer', 'clever-age') }}</span>
                        </button>
                    </header>
                    <Filters/>
                </section>
            </div>
        </aside>
        <div class="col-8 col-sm-12" ref="results">
            <Results :is-search-result="isSearchResult"/>
        </div>
    </div>
</template>

<script setup>
import {ref, watch} from 'vue';
import {useSyncSearchState} from '../composables/syncSearchState';
import {getThemeFileUri} from '../helpers';
import {usePaginationStore} from '../stores/pagination';
import {useSearchStore} from '../stores/search';
import {Filters, Results} from './search';

const {__} = wp.i18n;

const props = defineProps({
    isSearchResult: Boolean,
    defaultFilters: {
        type: Object,
        default: () => ({}),
    },
    filters: {
        type: Array,
        default: () => [],
    },
});

const {query, facets, defaultFilters, filters, search} = useSearchStore();
const {page, hitsPerPage} = usePaginationStore();
const results = ref(null);

defaultFilters.value = props.defaultFilters;
facets.value = Object.fromEntries(props.filters.filter(filter => filter.type === 'facet').map(filter => [filter.attribute, {}]));

const {syncUrlParams} = useSyncSearchState(props.isSearchResult, props.filters);

// Reset to the first page when the query or filters are updated
watch([query, filters], () => page.value = 0, {deep: true});

watch([query, filters, page, hitsPerPage], () => {
    search(props.isSearchResult);
    syncUrlParams({
        search: {
            query: query.value,
            filters: filters.value,
        },
        pagination: {
            page: page.value,
            hitsPerPage: hitsPerPage.value,
        },
    });
}, {immediate: true, deep: true});

watch([filters, page], () => {
    results.value.scrollIntoView({behavior: 'smooth'});
}, {deep: true});
</script>
