import {useUrlSearchParams} from '@vueuse/core';
import {ref} from 'vue';

export function useSyncStateWithUrlParams({paramsToState, stateToParams}) {
    const urlParams = useUrlSearchParams();
    const state = ref(paramsToState(urlParams));

    function syncUrlParams(newState) {
        Object.assign(state.value, newState);
        Object.assign(urlParams, stateToParams(state.value));
    }

    return {
        state,
        syncUrlParams,
    };
}
