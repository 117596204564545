<template>
    <label class="d-f ai-fs" :for="id">
        <input
            type="checkbox"
            :id="id"
            :name="name"
            v-model="model"
            v-bind="$attrs"
            v-indeterminate="indeterminate"
        />
        <slot/>
    </label>
</template>

<script setup>
const props = defineProps({
    id: String,
    name: String,
    indeterminate: Boolean,
});
defineOptions({
    inheritAttrs: false,
});

const model = defineModel();
</script>
