import {createPopper} from '@popperjs/core';
import {uniqueId} from './helpers';

export class Tooltip {
    constructor(element) {
        this.element = element;
        this.id = uniqueId('tooltip-');
        this.#createTooltip();
        this.popper = createPopper(this.element, this.tooltip, {
            placement: this.element.dataset.tooltipPosition || 'auto',
        });

        const showEvents = ['mouseenter', 'focus'];
        const hideEvents = ['mouseleave', 'blur'];

        showEvents.forEach(event => this.element.addEventListener(event, this.show.bind(this)));
        hideEvents.forEach(event => this.element.addEventListener(event, this.hide.bind(this)));
    }

    show() {
        this.element.appendChild(this.tooltip);
        this.element.setAttribute('aria-describedby', this.id);
        this.popper.update();
    }

    hide() {
        this.tooltip.remove();
        this.element.removeAttribute('aria-describedby');
    }

    #createTooltip() {
        this.tooltip = window.document.createElement('div');
        this.tooltip.id = this.id;
        this.tooltip.classList.add('tooltip');
        this.tooltip.role = 'tooltip';

        const content = window.document.createElement('div');
        content.classList.add('tooltip-content');
        content.innerHTML = this.element.dataset.tooltip;
        this.tooltip.appendChild(content);

        const arrow = window.document.createElement('div');
        arrow.classList.add('tooltip-arrow');
        arrow.setAttribute('data-popper-arrow', '');
        this.tooltip.appendChild(arrow);
    }
}
