import {createGlobalState} from '@vueuse/core';
import {reactive, toRefs} from 'vue';

export const usePaginationStore = createGlobalState(() => {
    const hitsPerPageOptions = [10, 20, 50];
    hitsPerPageOptions.default = hitsPerPageOptions[0];

    const state = reactive({
        page: 0,
        nbPages: 4,
        hitsPerPage: hitsPerPageOptions.default,
        hitsPerPageOptions,
    });

    return toRefs(state);
});
