import Splide from '@splidejs/splide';
import {i18n} from './i18n';

export function jobSlider(element) {

    let splide = new Splide(element, {
        pagination: false,
        mediaQuery: 'max',
        perPage: 2,
        perMove: 2,
        gap: '1.375rem',
        i18n : i18n,
        breakpoints: {
            768: {
                perPage: 1,
                perMove: 1,
            },
        }
    });

    splide.mount();
}
