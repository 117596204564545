import {UrlParams} from '@vueuse/core';
import {arrayFilterRecurisve, arrayWrap} from '../helpers';
import {usePaginationStore} from '../stores/pagination';
import {useSearchStore} from '../stores/search';
import {useSyncStateWithUrlParams} from './syncStateWithUrlParams';

export function useSyncSearchState(isSearchResult, initialFilters) {
    const {query, filters} = useSearchStore();
    const {page, hitsPerPage, hitsPerPageOptions} = usePaginationStore();

    const {state, syncUrlParams} = useSyncStateWithUrlParams({
        stateToParams(state) {
            return {
                s: isSearchResult ? state.search.query : null,
                page: state.pagination.page ? state.pagination.page + 1 : null,
                perPage: state.pagination.hitsPerPage === hitsPerPageOptions.value.default ? null : state.pagination.hitsPerPage,
                ...getUrlParamsFromFilters(state.search.filters),
            };
        },
        paramsToState(params) {
            return {
                search: {
                    query: params.s || '',
                    filters: initialFilters.map(filter => ({
                        ...filter, value: getFilterValueFromUrl(params, filter),
                    })),
                }, pagination: {
                    page: Math.max(0, parseInt(params.page || 1) - 1),
                    hitsPerPage: parseInt(params.perPage || hitsPerPageOptions.value.default),
                },
            };
        },
    });

    query.value = state.value.search.query;
    filters.value = state.value.search.filters;
    page.value = state.value.pagination.page;
    hitsPerPage.value = state.value.pagination.hitsPerPage;

    /**
     * @param {UrlParams} urlParams
     * @param {Filter} filter
     * @return {FacetFilterItem[]|NumericFilterItem}
     */
    function getFilterValueFromUrl(urlParams, filter) {
        if (filter.type === 'facet') {
            const selectedValues = arrayWrap(urlParams[filter.attribute]);
            if (!urlParams[filter.attribute]) {
                return [];
            }
            return arrayFilterRecurisve(filter.items, item => selectedValues.includes(item.value));
        }

        if (filter.type === 'numeric') {
            if (!urlParams[filter.attribute]) {
                return filter.items[0] || null;
            }
            const selectedValue = Object.fromEntries(
                urlParams[filter.attribute]
                    .split(',')
                    .map(datetime => datetime.split(':'))
                    .map(([position, datetime]) => [position, parseInt(datetime)]),
            );
            return filter.items.find(item => item.start === selectedValue.start && item.end === selectedValue.end);
        }
    }

    /**
     * @param {Filter[]} filters
     * @return {Object.<string, string>}
     */
    function getUrlParamsFromFilters(filters) {
        return Object.fromEntries(filters.map(filter => {
            if (filter.type === 'facet') {
                return [filter.attribute, filter.value.map(v => v.value)];
            }
            if (filter.type === 'numeric') {
                const value = [
                    filter.value.start ? `start:${filter.value.start}` : '',
                    filter.value.end ? `end:${filter.value.end}` : '',
                ];
                return [filter.attribute, value.filter(v => !!v).join(',') || null];
            }
        }));
    }

    return {
        syncUrlParams,
    };
}
