<template>
    <div>
        <form :action="action" class="search-results-form" @submit.prevent>
            <input
                type="search"
                name="s"
                :placeholder="__('Rechercher sur le site&hellip;', 'clever-age')"
                autocomplete="off"
                v-model="query"
            >
            <button type="submit">
                <span class="visually-hidden">{{ __('Rechercher', 'clever-age') }}</span>
                <svg tabindex="0" focusable="false" aria-hidden="true" width="18" height="18" fill="#98700e">
                    <use :xlink:href="getThemeFileUri('build/svg/sprite.svg#loupe')"></use>
                </svg>
            </button>
        </form>
        <p class="mt-s c-rum">
            {{
                sprintf(_n(
                    '%s résultat trouvé',
                    '%s résultats trouvés',
                    resultsCount,
                    'clever-age',
                ), resultsCount.toLocaleString())
            }}
        </p>
    </div>
</template>

<script setup>
import {getThemeFileUri} from '../helpers';
import {useSearchStore} from '../stores/search';

const {__, _n, sprintf} = wp.i18n;

const props = defineProps({
    action: String,
});

const {query, resultsCount} = useSearchStore();
</script>
