import lottie from 'lottie-web';
import {gsap} from '../gsap';
import {architecture, client, commerce, communication, platform, produit, rocket} from './animationsData';
import {offerSequence} from './offerSequence';

export function lottieAnimations() {
    const isReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
    if (isReducedMotion) {
        return;
    }

    const matchMedia = gsap.matchMedia();

    if (document.querySelector('.lottie-offer-block')) {
        const animations = {
            '.lottie-offer-commerce': commerce,
            '.lottie-offer-data-client': client,
            '.lottie-offer-data-produit': produit,
            '.lottie-offer-architecture': architecture,
            '.lottie-offer-communication': communication,
            '.lottie-offer-data-platform': platform,
        };

        for (const selector in animations) {
            const container = document.querySelector(selector);
            if (!container) {
                continue;
            }
            const animation = lottie.loadAnimation({
                container,
                renderer: 'svg',
                loop: false,
                autoplay: false,
                animationData: animations[selector],
                rendererSettings: {
                    progressiveLoad: false,
                },
            });

            gsap.timeline({
                scrollTrigger: {
                    trigger: selector,
                    start: 'top center',
                },
            }).to({frame: 0}, {
                frame: animation.totalFrames - 1,
                snap: 'frame',
                duration: 20,
                onEnter() {
                    animation.play();
                },
            });
        }
    }

    if (document.querySelector('.group-presentation-block')) {
        matchMedia.add('(min-width: 1024px)', () => {
            gsap.timeline({
                scrollTrigger: {
                    trigger: '.group-presentation-block',
                    start: 'top 65%',
                },
            }).from('.feature-list-item', {
                y: 20,
                duration: 0.8,
                opacity: 0,
                stagger: 0.3,
            });
        });
    }

    const rocketAnimContainer = document.getElementById('lottie-rocket-sequence');
    if (rocketAnimContainer) {
        const lottieRocketAnim = lottie.loadAnimation({
            container: rocketAnimContainer,
            renderer: 'svg',
            loop: false,
            autoplay: false,
            animationData: rocket[rocketAnimContainer.dataset.lang],
            name: 'rocket',
            rendererSettings: {
                progressiveLoad: false,
            },
        });

        const tlRocketSequence = gsap.timeline({
            scrollTrigger: {
                id: 'rocketSequence',
                trigger: '.group-rocket-block',
                start: 'top center',
            },
        });

        tlRocketSequence.to({frame: 0}, {
            frame: lottieRocketAnim.totalFrames - 1,
            snap: 'frame',
            duration: 20,
            onEnter() {
                lottieRocketAnim.play();
            },
        });
    }

    if (document.querySelector('.group-rocket-block')) {
        matchMedia.add('(min-width: 1024px)', () => {
            gsap.timeline({
                scrollTrigger: {
                    trigger: '.lottie-offer',
                    start: '50% bottom',
                    onEnter: () => {
                        if (document.querySelector('.lottie-offer')) {
                            document
                                .querySelector('.lottie-offer')
                                .classList.add('active');
                        }

                        if (
                            document.querySelector(
                                '.group-rocket-block',
                            )
                        ) {
                            document
                                .querySelector('.group-rocket-block')
                                .classList.add('active');
                        }
                    },
                },
            });
        });
    }

    const offerSequenceContainer = document.getElementById('lottie-offer-sequence');
    if (offerSequenceContainer) {
        matchMedia.add('(min-width: 1024px)', () => offerSequence(offerSequenceContainer));
    }

    if (document.querySelector('.splitlines')) {
        matchMedia.add('(min-width: 1024px)', () => {
            gsap.timeline({
                scrollTrigger: {
                    trigger: '.splitlines-block',
                    start: 'center bottom',
                },
            }).from('.splitlines p', {
                x: '-50%',
                duration: 1,
                opacity: 0,
                stagger: 0.3,
            });
        });
    }
}

export function agencyAnimations() {
    const agenciesMap = document.querySelectorAll('.agencies-map');
    const focusableElems = 'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])';

    for (const agencyMap of agenciesMap) {
        const agencyMapSvg = agencyMap.querySelector('.agency-marker-map');
        if (!agencyMapSvg) {
            continue;
        }
        const agencyGhostList = agencyMap.querySelector('.agencies-ghost-list');
        const markersSvgMap = agencyMapSvg.querySelectorAll('.agency-marker-map .agency-marker');
        const markers = agencyMap.querySelectorAll('.agency-marker');
        const agenciesCaption = agencyMap.querySelectorAll('.agency-caption');

        for (const agencyCaption of agenciesCaption) {
            if (!agencyCaption.classList.contains('active')) {
                agencyCaption
                    .querySelectorAll(focusableElems)
                    .forEach((elem) => {
                        elem.setAttribute('tabindex', -1);
                    });
            }
        }

        agencyGhostList.querySelectorAll('.agency-marker').forEach((agency) => {
            agency.addEventListener('focus', (e) => {
                let agencyId = e.target.closest('.agency').dataset.agencyId;

                for (let marker of markersSvgMap) {
                    marker.classList.remove('hover');
                }

                agencyMapSvg
                    .querySelector(
                        `[data-agency-id="${agencyId}"] .agency-marker`,
                    )
                    .classList.add('hover');
            });
        });

        for (let marker of markers) {
            marker.addEventListener('click', (e) => {
                let agencyId = marker.closest('.agency').dataset.agencyId;
                agencyGhostList
                    .querySelectorAll('[data-agency-id]')
                    .forEach((agency) => {
                        agency.setAttribute('aria-current', false);
                    });
                agencyGhostList
                    .querySelector(`[data-agency-id="${agencyId}"]`)
                    .setAttribute('aria-current', true);

                for (let marker of markersSvgMap) {
                    marker.closest('.agency').classList.remove('active');
                }

                agencyMapSvg
                    .querySelector(`[data-agency-id="${agencyId}"]`)
                    .classList.add('active');

                for (let agencyCaption of agenciesCaption) {
                    agencyCaption.classList.remove('active');
                    agencyCaption
                        .querySelectorAll(focusableElems)
                        .forEach((elem) => {
                            elem.setAttribute('tabindex', -1);
                        });
                }

                let currentAgencyCaption = agencyMap.querySelector(
                    `[data-marker-id="${agencyId}"]`,
                );
                currentAgencyCaption.classList.add('active');
                currentAgencyCaption
                    .querySelectorAll(focusableElems)
                    .forEach((elem) => {
                        elem.setAttribute('tabindex', 0);
                    });
            });
        }
    }
}
