<template>
    <button class="cta-link" type="button">
            <span class="mr-xxs">
                {{ showMore ? __('Voir moins de filtres', 'clever-age') : __('Voir plus de filtres', 'clever-age') }}
            </span>
        <svg focusable="false" aria-hidden="true" width="10" height="10">
            <use :xlink:href="getThemeFileUri(`build/svg/sprite.svg#chevron-${showMore ? 'up' : 'down'}`)"></use>
        </svg>
    </button>
</template>

<script setup>
import {getThemeFileUri} from '../helpers';

const {__} = wp.i18n;

defineProps({
    showMore: Boolean,
});
</script>
