import {ref} from 'vue';

export function useShowMore(limit) {
    const showMore = ref(false);

    function shouldShow(index) {
        return limit.value === 0 || showMore.value || index + 1 <= limit.value;
    }

    return {showMore, shouldShow};
}
