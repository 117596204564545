<template>
    <div v-if="results.length">
        <ul class="row">
            <component
                v-for="result in results"
                :is="getListItemComponent(result.type)"
                :item="result"
                :key="result.objectID"
            />
        </ul>
        <hr class="decoration-hr-star"/>
        <Pagination/>
    </div>

    <div v-else>
        <p class="tt-6">
            {{ __('Aucun résultat ne correspond à votre recherche.', 'clever-age') }}
        </p>
    </div>
</template>

<script setup>
import {Pagination} from '.';
import {useSearchStore} from '../../stores/search';
import {JobOfferItem, PostItem, SearchResultItem, WorkItem} from '../result-items';

const {__} = wp.i18n;

const props = defineProps({
    isSearchResult: Boolean,
});

const {results} = useSearchStore();

function getListItemComponent(type) {
    if (props.isSearchResult) {
        return SearchResultItem;
    }

    const components = {
        default: PostItem,
        work: WorkItem,
        job_offer: JobOfferItem,
    };

    return components[type] || components.default;
}
</script>
