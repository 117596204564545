import lottie from 'lottie-web';
import {gsap} from '../gsap';
import {offer} from './animationsData';

export function offerSequence(container) {
    const lottieOfferAnim = lottie.loadAnimation({
        container,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: offer,
        rendererSettings: {
            progressiveLoad: false,
        },
    });

    const speed = parseFloat(container.dataset.speed);
    const offset = '15%';
    const negativeOffset = `-${offset}`;

    gsap.set('.lottie-offer-text', {opacity: 0});
    gsap.set('.lottie-offer-text.right', {x: offset});
    gsap.set('.lottie-offer-text.left', {x: negativeOffset});

    const animationFrame = {frame: 0};
    gsap.timeline({
            defaults: {
                duration: 0.5,
            },
            scrollTrigger: {
                id: 'offerSequence',
                trigger: '#lottie-offer-sequence',
                start: 'center center',
                end: `+=${speed * 1000}px`,
                scrub: 0.2,
                pin: '.pin-container',
            },
        })
        .to(animationFrame, {
            frame: lottieOfferAnim.totalFrames - 1,
            snap: 'frame',
            duration: 20,
            onUpdate: () => lottieOfferAnim.goToAndStop(animationFrame.frame, true),
        })
        .to(
            '.lottie-offer-text.communication',
            {x: 0, opacity: 1, zIndex: 1},
            0,
        )
        .to(
            '.lottie-offer-text.communication',
            {x: offset, opacity: 0, zIndex: 0},
            1.1,
        )
        .to(
            '.lottie-offer-text.dataProd',
            {x: 0, opacity: 1, zIndex: 1},
            1.5,
        )
        .to(
            '.lottie-offer-text.dataProd',
            {x: negativeOffset, opacity: 0, zIndex: 0},
            2.8,
        )
        .to(
            '.lottie-offer-text.commerce',
            {x: 0, opacity: 1, zIndex: 1},
            3.2,
        )
        .to(
            '.lottie-offer-text.commerce',
            {x: offset, opacity: 0, zIndex: 0},
            4.8,
        )
        .to(
            '.lottie-offer-text.dataClient',
            {x: 0, opacity: 1, zIndex: 1},
            5,
        )
        .to(
            '.lottie-offer-text.dataClient',
            {x: negativeOffset, opacity: 0, zIndex: 0},
            6.5,
        )
        .to(
            '.lottie-offer-text.architecture',
            {x: 0, opacity: 1, zIndex: 1},
            7.2,
        )
        .to(
            '.lottie-offer-text.architecture',
            {x: offset, opacity: 0, zIndex: 0},
            9.5,
        )
        .to(
            '.lottie-offer-text.data-platform',
            {x: 0, opacity: 1, zIndex: 1},
            10,
        );
}
