import {createGlobalState} from '@vueuse/core';
import {computed, reactive} from 'vue';
import {searchClient} from '../algolia';

const index = searchClient.initIndex(window.CleverAge.algolia.indexName);

export const useSuggestionsStore = createGlobalState(() => {
    const suggestions = reactive([]);

    const suggestionsByType = computed(() => {
        const suggestionsByType = {};
        suggestions.forEach(suggestion => {
            if (!suggestionsByType[suggestion.type]) {
                suggestionsByType[suggestion.type] = [];
            }
            suggestionsByType[suggestion.type].push(suggestion);
        });

        return suggestionsByType;
    });

    async function fetchSuggestions(query, lang) {
        if (!query) {
            suggestions.splice(0);
            return;
        }

        const {hits} = await index.search(query, {
            facetFilters: [
                `lang:${lang}`,
            ],
            hitsPerPage: 5,
        });
        suggestions.splice(0);
        suggestions.push(...hits);
    }

    return {
        suggestions,
        suggestionsByType,
        fetchSuggestions,
    };
});
