<template>
    <li class="p-r mb-xxxl col-12">
        <figure class="d-f ai-c fd-c@<sm ai-fs@<sm">
            <div class="fs-0 fg-0 page-category-list-img mr-s mr-no@sm">
                <img class="img-full obf-cont" :src="item.thumbnail" :alt="item.thumbnailAlt" v-if="item.thumbnail">
            </div>
            <figcaption class="fg-1  mt-l@<sm">
                <h4 class="tt-6 fw-700">
                    <a :href="item.permalink" class="cta-link-stretched cta-link--reverse" v-html="item.title"></a>
                </h4>
                <span class="tt-7 mt-xxxs d-b c-rum">
                    {{ __('Publié le', 'clever-age') }}
                    <time :datetime="item.createdAt.toISO()">
                        {{ item.createdAt.toLocaleString() }}
                    </time>
                    <span v-if="isBlogPost && item.authors && item.authors.length">
                        {{ sprintf(__(' par %s', 'clever-age'), item.authors.join(', ')) }}
                    </span>
                </span>
                <ul class="mt-m tag-list">
                    <li v-for="tag in tags"
                        class="tag mr-xs mb-xs"
                        :class="{'tag-rum': isHighlightedCategory(tag)}">
                        {{ tag }}
                    </li>
                </ul>
            </figcaption>
        </figure>
    </li>
</template>

<script setup>
import {computed, toRefs} from 'vue';

const {__, sprintf} = wp.i18n;

const props = defineProps({
    item: Object,
});
const {item} = toRefs(props);

const isBlogPost = computed(() => item.value.categories.includes(window.CleverAge.blogCategory));

const tags = computed(() => {
    const notHighlightedCategories = item.value.categories.filter(category => !item.value.highlightedCategories.includes(category));
    const categories = item.value.highlightedCategories.concat(notHighlightedCategories.slice(1)).slice(0, 2);
    const offerCategories = item.value.offerCategories.slice(0, 2);

    return categories.length ? categories : offerCategories;
});

function isHighlightedCategory(category) {
    return item.value.highlightedCategories.includes(category);
}
</script>
