/**
 * @param {Element} element
 * @param {string} optionFocusableElems
 * @return {{all: NodeListOf<Element>, last: Element, first: Element}}
 */
export function focusableElems(element, optionFocusableElems) {
    const defaultFocusableContent = 'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled], [tabindex="0"])';
    const focusableEls = element.querySelectorAll(optionFocusableElems ? defaultFocusableContent + ',' + optionFocusableElems : defaultFocusableContent);

    return {
        'all': focusableEls,
        'first': focusableEls[0],
        'last': focusableEls[focusableEls.length - 1],
    };
}

export function trapFocus(element, optionFocusableElems, onlyVisibleElems) {
    let firstFocusableEl = focusableElems(element).first;
    let lastFocusableEl = focusableElems(element).last;

    let KEYCODE_TAB = 9;

    function putTrap(e) {

        let isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);

        if (!isTabPressed) {
            return;
        }

        if (e.shiftKey) /* shift + tab */ {
            if (document.activeElement === firstFocusableEl) {
                lastFocusableEl.focus();
                e.preventDefault();
            }
        } else /* tab */ {
            if (document.activeElement === lastFocusableEl) {
                firstFocusableEl.focus();
                e.preventDefault();
            }
        }
        if (!element.removeTrapFocus) {
            element.removeTrapFocus = () => {
                element.removeEventListener('keydown', putTrap);
            };
        }
    }

    element.addEventListener('keydown', putTrap);

    firstFocusableEl.focus();
}

export function arrayWrap(value) {
    if (value === undefined) {
        return [];
    }
    return Array.isArray(value) ? [...value] : [value];
}

export function clone(value) {
    return JSON.parse(JSON.stringify(value));
}

export function isNumeric(value) {
    return /^-?\d+$/.test(value);
}

/**
 * @param {[]} array
 * @param {function} callback
 * @param {string} property
 */
export function arrayEachRecursive(array, callback, property = 'children') {
    array.forEach(item => {
        if (callback(item) !== false && Array.isArray(item[property])) {
            arrayEachRecursive(item[property], callback, property);
        }
    });
}

/**
 * @param {[]} array
 * @param {function} callback
 * @param {string} property
 */
export function arrayFilterRecurisve(array, callback, property = 'children') {
    let found = [];
    arrayEachRecursive(array, item => {
        if (callback(item)) {
            found.push(item);
        }
    }, property);
    return found;
}

/**
 * @param {number} number
 * @param {number} min
 * @param {number} max
 * @return {number}
 */
export function clamp(number, min, max) {
    return Math.min(Math.max(number, min), max);
}

/**
 * @param {string} path
 * @return {string}
 */
export function getThemeFileUri(path) {
    return `${window.CleverAge.themeUri}/${path}`;
}

let idCounter = 0;
export function uniqueId(prefix) {
    const id = ++idCounter + '';
    return prefix ? prefix + id : id;
}
