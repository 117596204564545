import rocketEn from './rocket-en.json';
import rocketFr from './rocket-fr.json';

export {default as architecture} from './architecture.json';
export {default as commerce} from './commerce.json';
export {default as communication} from './communication.json';
export {default as client} from './data-client.json';
export {default as produit} from './data-produit.json';
export {default as offer} from './offer.json';
export {default as platform} from './data-platform.json';
export const rocket = {
    fr: rocketFr,
    en: rocketEn,
};
