import Splide from '@splidejs/splide';
import {i18n} from './i18n';

export function agencySlider(element) {
    let splide = new Splide(element, {
        pagination: true,
        mediaQuery: 'min',
        perPage: 1,
        height:'26.25rem',
        trimSpace: false,
        arrows: false,
        i18n : i18n
        
    });

    splide.mount();
}