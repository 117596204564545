import {Icon, Map, Marker, TileLayer} from 'leaflet';
import {getThemeFileUri} from '../helpers';

const {__} = wp.i18n;

export class AgencyMap {
    constructor(mapElem) {
        this.mapElem = mapElem;
        this.config = JSON.parse(this.mapElem.dataset.mapConfig);
        this.createMap();
    }

    createMap() {
        const agencyCoordinates = [this.config.lat, this.config.lng];
        const marker = new Marker(agencyCoordinates, {
            icon: new Icon({
                iconUrl: getThemeFileUri('assets/svg/marker-map.svg'),
                iconAnchor: [14, 34],
            }),
        });
        new Map(this.mapElem, {
            center: agencyCoordinates,
            zoom: 15,
            layers: [
                new TileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    maxZoom: 19,
                    attribution: __('&copy; Contributeurs <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>', 'clever-age'),
                }),
                marker,
            ],
        });
        const popupContent = `
            <h2 class="c-dark fw-700 tt-6 mb-xs">${this.config.title}</h2>
            <address class="c-dark tt-7">${this.config.address}</address>
        `;
        marker.bindPopup(popupContent, {offset: [0, -27]}).openPopup();
    }
}
