<template>
    <div>
        <ul>
            <li class="mt-xs" v-for="(item, i) in items" v-show="shouldShow(i)">
                <label class="d-f">
                    <input
                        type="radio"
                        :name="name"
                        :value="item"
                        v-model="selectedItem"
                    />
                    <span class="ml-xxs">{{ item.label }}</span>
                </label>
            </li>
        </ul>
        <ShowMoreButton
            v-if="showMoreLimit && showMoreLimit < items.length"
            :show-more="showMore"
            @click="showMore = !showMore"
        />
    </div>
</template>

<script setup>
import {toRef} from 'vue';
import {useShowMore} from '../../composables';
import ShowMoreButton from '../ShowMoreButton.vue';

const props = defineProps({
    name: String,
    items: {
        type: Array,
        default: () => [],
    },
    showMoreLimit: {
        type: Number,
        default: 0,
    },
});

const selectedItem = defineModel({
    type: Object,
    default: () => ({}),
});

const {showMore, shouldShow} = useShowMore(toRef(props, 'showMoreLimit'));
</script>
