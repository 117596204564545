import Splide from '@splidejs/splide';
import {i18n} from './i18n';

export function simpleSlider(element) {
    let perPage = Number(element.dataset.splidePerpage);

    let splide = new Splide(element, {
        pagination: false,
        mediaQuery: 'max',
        perPage: Number.isInteger(perPage) ? perPage : 1,
        fixedWidth: Number.isInteger(perPage) ? false : `calc(100% / ${perPage})`,
        gap: '1.375rem',
        i18n : i18n,
        breakpoints: {
            768: {
                arrows: false,
                pagination: true,
                perPage: 1,
                fixedWidth: Number.isInteger(perPage) ? false : `100%`,
            },
        },
    });

    splide.mount();
}
