<template>
    <li class="p-r mb-xxxl col-12">
        <h4 class="mb-xxs tt-6 fw-700">
            <a :href="item.permalink" class="cta-link-stretched cta-link--reverse">
                <HighlightResult :item="item" property="title"/>
            </a>
        </h4>
        <span class="tt-7 mt-xxxs d-b c-rum">
            {{ __('Publié le', 'clever-age') }}
            <time :datetime="item.createdAt.toISO()">{{ item.createdAt.toLocaleString() }}</time>
        </span>
        <div class="mbl-m">
            <HighlightResult :item="item" property="content"/>
        </div>
        <ul class="mt-m fw-w tag-list">
            <template v-if="item.type === 'post' && item.categories.length">
                <li v-for="(category, i) in item.categories"
                    class="tag mr-xs mb-xs"
                    :class="{'tag-rum': i === 0}">
                    {{ category }}
                </li>
            </template>
            <li class="tag tag-rum mr-xs mb-xs" v-else>
                {{ item.typeLabel }}
            </li>
            <template v-if="item.workTypes && item.workTypes.length">
                <li class="tag mr-xs mb-xs" v-for="workType in item.workTypes">
                    {{ workType }}
                </li>
            </template>
            <li class="tag mr-xs mb-xs" v-for="offerCategory in item.offerCategories">
                {{ offerCategory }}
            </li>
        </ul>
    </li>
</template>

<script setup>
import HighlightResult from '../utils/HighlightResult.vue';

const {__, sprintf} = wp.i18n;

const props = defineProps({
    item: Object,
});
</script>

