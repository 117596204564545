import {computed} from 'vue';
import {ItemState} from '../components/widgets/HierarchicalMenu/ItemState';

/**
 * @typedef TreeNode
 * @type {object
 * @property {string} value
 * @property {number} count
 * @property {string} state
 * @property {TreeNode[]} children
 */

export function useTree(items, itemsCount, selectedItems) {
    const tree = computed(() => buildTree(items.value));

    /**
     * @param {[]} items
     * @return {TreeNode[]}
     */
    function buildTree(items) {
        return items.map(item => ({
                value: item.value,
                count: itemsCount.value[item.value] || 0,
                state: selectedItems.value.find(i => i.value === item.value) ? ItemState.Checked : ItemState.Unchecked,
                children: buildTree(item.children),
            }))
            .filter(item => item.count > 0)
            .sort((itemA, itemB) => itemB.count - itemA.count);
    }

    return {
        tree,
    };
}
