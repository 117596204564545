<template>
    <li class="p-r col-6 col-sm-12 work-item">
        <figure class="card mb-l">
            <div class="card-img">
                <img class="img-full" :src="item.thumbnail" :alt="item.thumbnailAlt" v-if="item.thumbnail"/>
            </div>
            <figcaption class="card-caption">
                <p class="fw-700 tt-6">
                    {{ item.clients[0] }}
                </p>
                <h3>
                    <a :href="item.permalink" class="cta-link-stretched cta-link--reverse" v-html="item.title"></a>
                </h3>
                <div class="card-pubtime">
                    {{ __('Publié le', 'clever-age') }}
                    <time :datetime="item.createdAt.toISO()">
                        {{ item.createdAt.toLocaleString() }}
                    </time>
                </div>
            </figcaption>
        </figure>
    </li>
</template>

<script setup>
const {__} = wp.i18n;

const props = defineProps({
    item: Object,
});
</script>
