<template>
    <div>
        <ul>
            <Node
                v-for="(node, i) in tree"
                v-show="shouldShow(i)"
                :key="node.value"
                :name="name"
                :node="node"
                @change="toggleItem"
            />
        </ul>
        <ShowMoreButton
            v-if="showMoreLimit && showMoreLimit < tree.length"
            :show-more="showMore"
            @click="showMore = !showMore"
        />
    </div>
</template>

<script setup>
import {toRefs} from 'vue';
import {useShowMore, useTree} from '../../../composables';
import ShowMoreButton from '../../ShowMoreButton.vue';
import Node from './Node.vue';

const {__} = wp.i18n;

defineOptions({
    name: 'HierarchicalMenu',
});

const props = defineProps({
    name: String,
    items: {
        type: Array,
        default: () => [],
    },
    itemsCount: {
        type: Object,
        default: () => ({}),
    },
    showMoreLimit: {
        type: Number,
        default: 0,
    },
});

const selectedItems = defineModel({
    type: Array,
    default: () => [],
});

const {showMoreLimit, items, itemsCount} = toRefs(props);

const {showMore, shouldShow} = useShowMore(showMoreLimit);
const {tree} = useTree(items, itemsCount, selectedItems);

function toggleItem(node) {
    const index = selectedItems.value.findIndex(item => item.value === node.value);
    if (index === -1) {
        selectedItems.value.push({value: node.value});
    } else {
        selectedItems.value.splice(index, 1);
    }
}
</script>
