import Splide from '@splidejs/splide';
import {i18n} from './i18n';

export function lastWorksSlider(element) {
    let splide = new Splide(element, {
        pagination: true,
        mediaQuery: 'min',
        perPage: 1,
        padding: {  right: '15%' },
        trimSpace: false,
        arrows: false,
        i18n : i18n,
        breakpoints: {
            768: {
                arrows: false,
                destroy: true,
            },
        },
    });

    splide.mount();
}
