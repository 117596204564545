<template>
    <nav class="pagination d-f jc-c pb-xxl"
         role="navigation"
         :aria-label="__('Navigation de la pagination', 'clever-age')">
        <div class="mr-xxs">
            <button class="pagination-control left" type="button" :disabled="isFirstPage" @click="goToPage(firstPage)">
                <svg focusable="false" aria-hidden="true" width="10" height="10">
                    <use :xlink:href="getThemeFileUri('build/svg/sprite.svg#chevron-double-left')"></use>
                </svg>
                <span class="visually-hidden">
                    {{ getNavigationButtonLabel(firstPage) }}
                </span>
            </button>
            <button class="pagination-control left" type="button" :disabled="isFirstPage" @click="goToPage(prevPage)">
                <svg focusable="false" aria-hidden="true" width="6" height="10">
                    <use :xlink:href="getThemeFileUri('build/svg/sprite.svg#chevron-left')"></use>
                </svg>
                <span class="visually-hidden">
                    {{ getNavigationButtonLabel(prevPage) }}
                </span>
            </button>
        </div>

        <ul class="pagination-list">
            <li v-for="page in pageNumbers" class="pagination-item" :key="page">
                <span v-if="page === -1">&hellip;</span>
                <button type="button"
                        @click="goToPage(page)"
                        :disabled="isCurrentPage(page)"
                        :aria-label="getPageNumberLabel(page)"
                        :aria-current="isCurrentPage(page)"
                        v-else>
                    {{ page + 1 }}
                </button>
            </li>
        </ul>
        <div class="ml-xxs">
            <button class="pagination-control right" type="button" :disabled="isLastPage" @click="goToPage(nextPage)">
                <svg focusable="false" aria-hidden="true" width="6" height="10">
                    <use :xlink:href="getThemeFileUri('build/svg/sprite.svg#chevron-left')"></use>
                </svg>
                <span class="visually-hidden">
                    {{ getNavigationButtonLabel(nextPage) }}
                </span>
            </button>
            <button class="pagination-control right" type="button" :disabled="isLastPage" @click="goToPage(lastPage)">
                <svg focusable="false" aria-hidden="true" width="10" height="10">
                    <use :xlink:href="getThemeFileUri('build/svg/sprite.svg#chevron-double-left')"></use>
                </svg>
                <span class="visually-hidden">
                    {{ getNavigationButtonLabel(lastPage) }}
                </span>
            </button>
        </div>
        <form class="pagination-form">
            <label for="hits-per-page" class="visually-hidden">
                {{ __('Nombre de résultats par page', 'clever-age') }}
            </label>
            <select id="hits-per-page" v-model="hitsPerPage" @change="goToPage(firstPage)">
                <option v-for="option in hitsPerPageOptions" :value="option">
                    {{ option }}
                </option>
            </select>
        </form>
    </nav>
</template>

<script setup>
import {computed, ref} from 'vue';
import {clamp, getThemeFileUri} from '../../helpers';
import {usePaginationStore} from '../../stores/pagination';

const {__, sprintf} = wp.i18n;

const {page, nbPages, hitsPerPage, hitsPerPageOptions} = usePaginationStore();

/**
 * Algolia page numbers are zero-based
 * @link https://www.algolia.com/doc/api-reference/api-parameters/page/#how-to-use
 */
const firstPage = ref(0);
const lastPage = computed(() => nbPages.value - 1);
const prevPage = computed(() => getPageNumber(-1));
const nextPage = computed(() => getPageNumber(1));
const isFirstPage = computed(() => isCurrentPage(firstPage.value));
const isLastPage = computed(() => isCurrentPage(lastPage.value));
const pageNumbers = computed(() => {
    const pageNumbers = Array.from(new Set([
        firstPage.value,
        getPageNumber(-2),
        prevPage.value,
        page.value,
        nextPage.value,
        getPageNumber(2),
        lastPage.value,
    ]));
    // -1 represents the ellipsis
    // Insert an ellipsis between the first and second page numbers and the last and second last page numbers
    // only there is more than one page difference, ie: [1, '...', 3, 4, 5, '...', 7].
    if (pageNumbers[1] - pageNumbers[0] > 1) {
        pageNumbers.splice(1, 0, -1);
    }
    if (pageNumbers[pageNumbers.length - 1] - pageNumbers[pageNumbers.length - 2] > 1) {
        pageNumbers.splice(-1, 0, -1);
    }
    return pageNumbers;
});

const isCurrentPage = p => page.value === p;
const getPageNumber = offset => clamp(page.value + offset, firstPage.value, lastPage.value);
const getPageNumberLabel = page => isCurrentPage(page) ?
    sprintf(__('Page actuelle, Page %d', 'clever-age'), page + 1) :
    sprintf(__('Page %d', 'clever-age'), page + 1);
const getNavigationButtonLabel = page => sprintf(__('Vers la page %d', 'clever-age'), page + 1);
const goToPage = p => page.value = p;
</script>
