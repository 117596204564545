import {createGlobalState} from '@vueuse/core';
import {DateTime} from 'luxon';
import {computed, reactive, toRefs} from 'vue';
import {searchClient} from '../algolia';
import {usePaginationStore} from './pagination';

/**
 * @typedef Filter
 * @type {object}
 * @property {string} label
 * @property {string} attribute
 * @property {'facet'|'numeric'} type
 * @property {FacetFilterItem[]|NumericFilterItem[]} items
 * @property {FacetFilterItem[]|NumericFilterItem} value
 */
/**
 * @typedef FacetFilterItem
 * @type {object}
 * @property {string} label
 * @property {string} value
 * @property {FacetFilterItem[]} children
 */
/**
 * @typedef NumericFilterItem
 * @type {object}
 * @property {string} label
 * @property {?number} start
 * @property {?number} end
 */

const index = searchClient.initIndex(window.CleverAge.algolia.indexName);
const indexByDateDesc = searchClient.initIndex(`${window.CleverAge.algolia.indexName}_date_desc`);

function serializeNumericFilter(attribute, start, end) {
    if (start && end) {
        return `${attribute}:${start} TO ${end}`;
    }
    if (start) {
        return `${attribute} >= ${start}`;
    }
    if (end) {
        return `${attribute} <= ${end}`;
    }
    return '';
}

export const useSearchStore = createGlobalState(() => {
    const state = reactive({
        query: '',
        defaultFilters: {},
        filters: [],
        results: [],
        resultsCount: 0,
        facets: {},
    });

    const paginationStore = usePaginationStore();

    // Default filters
    const defaultFiltersQuery = computed(() => {
        return Object.entries(state.defaultFilters).map(filter => filter.join(':'));
    });

    // Facet filters
    const facetFilters = computed(() => state.filters.filter(filter => filter.type === 'facet'));
    const facetFiltersQuery = computed(() => {
        return facetFilters.value.flatMap(filter => {
            return filter.value.map(selectedItem => `${filter.attribute}:${selectedItem.value}`);
        });
    });
    const selectedFacetFilters = computed(() => {
        return Object.fromEntries(facetFilters.value.map(filter => [filter.attribute, filter.value]));
    });
    const isSomeFacetFilterSelected = computed(() => {
        return Object.values(selectedFacetFilters.value).some(values => values.length);
    });

    // Numeric filters
    const numericFilters = computed(() => state.filters.filter(filter => filter.type === 'numeric'));
    const numericFiltersQuery = computed(() => {
        return numericFilters.value
            .flatMap(filter => serializeNumericFilter(filter.attribute, filter.value.start, filter.value.end))
            .filter(filter => filter.length);
    });

    async function search(isSearchResult) {
        const {
            hits,
            facets,
            nbHits,
            nbPages,
        } = await (isSearchResult ? index : indexByDateDesc).search(state.query, {
            facets: facetFilters.value.map(filter => filter.attribute),
            facetFilters: [
                ...defaultFiltersQuery.value,
                ...facetFiltersQuery.value,
            ],
            numericFilters: numericFiltersQuery.value,
            page: paginationStore.page.value,
            hitsPerPage: paginationStore.hitsPerPage.value,
            distinct: true,
            facetingAfterDistinct: true,
        });
        state.results = hits.map(hit => ({
            ...hit,
            createdAt: DateTime.fromSeconds(hit.createdAt),
        }));
        state.resultsCount = nbHits;
        state.facets = facets;
        paginationStore.nbPages.value = nbPages;
    }

    return {
        index,
        ...toRefs(state),
        selectedFacetFilters,
        isSomeFacetFilterSelected,
        search,
    };
});
