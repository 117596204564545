import {createApp} from 'vue';
import * as directives from '../directives';

export {default as Search} from './Search.vue';
export {default as HeaderSearchForm} from './HeaderSearchForm.vue';
export {default as SearchResultsForm} from './SearchResultsForm.vue';

export function initVueComponents(components) {
    document.querySelectorAll('[data-component]').forEach(el => {
        const componentName = el.dataset.component;
        if (!(componentName in components)) {
            console.warn(`Component "${componentName}" not found.`);
            return;
        }
        const component = components[componentName];
        const props = JSON.parse(el.dataset.props || '{}');
        const app = createApp(component, props);
        for (const directive in directives) {
            app.directive(directive, directives[directive]);
        }
        app.mount(el);
    });
}
