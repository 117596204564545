<template>
    <div class="pin-xl@<sm pb-m@<sm">
        <template v-if="isSomeFacetFilterSelected">
            <button class="d-f ai-c cta-link" type="button" @click="resetFilters()">
                <span class="mr-xs ">{{ __('Réinitialiser les filtres', 'clever-age') }}</span>
                <svg focusable="false" aria-hidden="true" width="20" height="16">
                    <use :xlink:href="getThemeFileUri('build/svg/sprite.svg#reinit')"></use>
                </svg>
            </button>

            <ul class="mt-s d-f fw-w">
                <template v-for="(selectedFilters, attribute) in selectedFacetFilters">
                    <li class="tag mb-xxs mr-xs" v-for="selectedFilter in selectedFilters">
                        <span class="mr-xxs">{{ selectedFilter.value }}</span>
                        <button type="button" @click="removeFilter(selectedFilter, attribute)">
                            <svg focusable="false" aria-hidden="true" width="8" height="8">
                                <use :xlink:href="getThemeFileUri('build/svg/sprite.svg#cross')"></use>
                            </svg>
                            <span class="visually-hidden">{{ __('Supprimer le filtre', 'clever-age') }}</span>
                        </button>
                    </li>
                </template>
            </ul>
        </template>

        <section v-for="(filter, i) in visibleFilters"
                 :class="{'mt-l': i !== 0 || isSomeFacetFilterSelected}"
                 :key="filter.attribute">
            <header>
                <h3 class="tt-5 tt-u fw-700 mb-xl tt-dotted pb-xs">
                    {{ filter.label }}
                </h3>
            </header>

            <HierarchicalMenu
                v-if="filter.type === 'facet'"
                :name="filter.attribute"
                :items="filter.items"
                :items-count="facets[filter.attribute]"
                :show-more-limit="4"
                v-model="filter.value"
            />
            <NumericMenu
                v-else-if="filter.type === 'numeric'"
                :name="filter.attribute"
                :items="filter.items"
                :show-more-limit="4"
                v-model="filter.value"
            />
        </section>
    </div>
</template>

<script setup>
import {computed} from 'vue';
import {getThemeFileUri} from '../../helpers';
import {useSearchStore} from '../../stores/search';
import {HierarchicalMenu, NumericMenu} from '../widgets';

const {__} = wp.i18n;

const {filters, facets, isSomeFacetFilterSelected, selectedFacetFilters} = useSearchStore();

const visibleFilters = computed(() => filters.value.filter(filter => !isFilterEmpty(filter)));

/**
 * @param {Filter} filter
 * @return {boolean}
 */
function isFilterEmpty(filter) {
    if (filter.type === 'facet') {
        return !(filter.attribute in facets.value);
    }
    if (filter.type === 'numeric') {
        return filter.items.length === 0;
    }
    return false;
}

/**
 * @param {Filter} selectedFilter
 * @param {string} attribute
 */
function removeFilter(selectedFilter, attribute) {
    const filter = filters.value.find(f => f.attribute === attribute);
    const index = filter.value.findIndex(v => v.value === selectedFilter.value);
    if (index !== -1) {
        filter.value.splice(index, 1);
    }
}

function resetFilters() {
    for (const filter of filters.value) {
        filter.value = filter.type === 'facet' ? [] : (filter.items[0] || null);
    }
}
</script>
