<template>
    <span v-html="value" class="highlight-result"></span>
</template>

<script setup>
import {computed} from 'vue';

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
    property: {
        type: String,
        required: true,
    },
});

const value = computed(() => {
    return props.item._snippetResult[props.property]?.value || props.item._highlightResult[props.property]?.value || props.item[props.property];
});
</script>
