import { trapFocus, focusableElems } from './helpers';

export class Nav {

    constructor() {
        this.nav                = document.querySelector('#main-menu');
        this.navMenuListWrapper = document.querySelector('.menu-item-list-wrapper');
        this.navMenuList        = document.querySelector('.menu-item-list');

        this.navBurger          = document.querySelector('.burger-button');
        this.navMobileClose     = document.querySelector('.nav-mobile-close');
        this.navItemsBtn        = this.nav.querySelectorAll('.menu-item-button');
        this.subMenu            = this.nav.querySelectorAll('.sub-menu');
        this.mediaQuery         = window.matchMedia('(max-width: 768px)');

        this.events();
    }


    events() {

        document.addEventListener('click', e => {
            this.closeOpenSubMenu();
        })

        this.nav.addEventListener('click', e => {
            e.stopPropagation();
        })

        this.navBurger.addEventListener('click', e => {
            this.navBurger.classList.add('active');
            this.navMenuListWrapper.classList.toggle('active');
            this.navMenuListWrapper.classList.add('activated');
            document.querySelector('html').style.overflow = 'hidden';

            for (let focusableEl of focusableElems(this.navMenuListWrapper).all) {
                focusableEl.setAttribute('tabindex', '0')
            }
        })

        this.navMobileClose.addEventListener('click', e => {
            this.resetMobileMenu();
        })

        this.navMenuListWrapper.addEventListener('click', e => {
            this.resetMobileMenu();
        })

        this.navMenuList.addEventListener('click', e => {
            e.stopPropagation();
        })

        for (let subMenu of this.subMenu) {
            trapFocus(subMenu);
        }

        document.addEventListener('keyup', e => {
            if (e.key === "Escape") {
                this.closeOpenSubMenu();

                if (e.target.closest('.menu-item-has-children')) {
                    e.target.closest('.menu-item-has-children').querySelector('button').focus();
                }
            }
        })

        for (let navItemBtn of this.navItemsBtn) {
            navItemBtn.addEventListener('click', e => {
                this.toggleSubMenu(navItemBtn);
            })
        }

        // MQ

        if (this.mediaQuery.matches) {
            trapFocus(this.navMenuListWrapper);
        }

        this.mediaQuery.addEventListener('change', (e) => {

            if (e.matches) {
                trapFocus(this.navMenuListWrapper);

                for (let focusableEl of focusableElems(this.navMenuListWrapper).all) {
                    focusableEl.setAttribute('tabindex', '-1')
                }

            } else {

                this.resetMobileMenu();

                for (let focusableEl of focusableElems(this.navMenuListWrapper).all) {
                    focusableEl.setAttribute('tabindex', '0')
                }

                this.navMenuListWrapper.classList.remove('activated');

                if (this.navMenuListWrapper.removeTrapFocus) {
                    this.navMenuListWrapper.removeTrapFocus();
                }
            }
        });
    }


    resetMobileMenu() {
        this.navMenuListWrapper.classList.remove('active');
        this.navBurger.classList.remove('active');
        this.navBurger.focus();
        for (let focusableEl of focusableElems(this.navMenuListWrapper).all) {
            focusableEl.setAttribute('tabindex', '-1')
        }
        document.querySelector('html').style.overflow = 'auto';
    }

    closeOpenSubMenu() {

        for (let navItemBtn of this.navItemsBtn) {

            let subMenu = navItemBtn.nextElementSibling;
            subMenu.classList.remove('active');
            navItemBtn.setAttribute('aria-expanded', false);
        }
    }

    toggleSubMenu(navItemBtn) {
        let subMenu = navItemBtn.nextElementSibling;
        let shouldOpen = !subMenu.classList.contains('active');

        this.closeOpenSubMenu();

        if (shouldOpen) {
            subMenu.classList.add('active');
            navItemBtn.setAttribute('aria-expanded', true);
        } else {
            subMenu.classList.remove('active');
            navItemBtn.setAttribute('aria-expanded', false);
        }
    }
}
