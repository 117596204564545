import {useEventListener} from '@vueuse/core';
import {ref} from 'vue';
import {ScrollDirection} from '../ScrollDirection';

export function useScrollDirection() {
    const scrollDirection = new ScrollDirection();
    const direction = ref(scrollDirection.detect());

    useEventListener(window, 'scroll', () => direction.value = scrollDirection.detect(), {passive: true});

    return direction;
}
